@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Nunito, system-ui, sans-serif;
  }

  body {
    @apply bg-neutral-n12;
  }

  #root {
    @apply h-screen;
  }
}

/* .grecaptcha-badge {
  visibility: hidden;
} */
